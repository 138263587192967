import {
    Modal,
    ModalBody,
    ModalCloseButton,
    ModalContent,
    ModalFooter,
    ModalOverlay,
    useDisclosure,
    Box,
} from '@chakra-ui/react'
import { Button } from '@irishlife/ilgroupdesignsystem.atoms.button'
import { Card } from '@irishlife/ilgroupdesignsystem.atoms.card'
import { Text } from '@irishlife/ilgroupdesignsystem.atoms.text'
import { ArrowRightCircleIcon } from '@irishlife/ilgroupdesignsystem.icons'
import { navigate } from '@reach/router'
import { RichText } from 'common/components/molecules/RichText'
import { config } from 'common/components/organisms/Calculator/config/index'
import { useCalculatorMachine } from 'common/components/organisms/Calculator/utils/context'
import { modifySteps } from 'common/components/organisms/Calculator/utils/modifySteps'
import React, { useEffect } from 'react'
import { useForm } from 'react-hook-form'
import { CalculatorStep, CalculatorStepProps } from './CalculatorStep'
import { CalculatorMachineEvent } from 'common/components/organisms/Calculator/utils/interfaces'
export interface CalculatorStepCommonProps {
    props?: any
}

export const CalculatorStepCommon: React.FC<CalculatorStepCommonProps> = ({
    props,
}) => {
    const { state, send } = useCalculatorMachine()
    const {
        register,
        handleSubmit,
        control,
        watch,
        formState: { isDirty, errors, isValid },
    } = useForm({
        shouldUnregister: false,
        mode: 'all',
    })

    const isFetching = state.matches('Getting Quote Details')
    const { isOpen, onOpen, onClose } = useDisclosure()
    const finalRef = React.useRef(null)
    const calculatorType = props.calc_type
    const analyticsName = `${calculatorType}_calculator`
    const { stepsTransformation } = config[calculatorType]
    const calculatorSteps = modifySteps(props?.steps)
    const formValues = watch()
    let transformedSteps = stepsTransformation(calculatorSteps, formValues)
    const questions = transformedSteps?.[state.context.screenNo].steps
    const formValuestoCheck = Object.values(questions).map((question: any) => {
        return question?.fieldName
    })
    const isFormComplete = Object.values(formValuestoCheck).every(
        (value) =>
            formValues.hasOwnProperty(value) &&
            formValues[value] !== undefined &&
            formValues[value] !== ''
    )
    const isSubmit = state.context.screenNo === transformedSteps?.length - 1
    useEffect(() => {
        if (isDirty) {
            send({
                type: 'Interacted',
                payload: {
                    name: analyticsName,
                    event: 'interacted',
                    isInteracted: 'true',
                },
            } as CalculatorMachineEvent)
        }
    }, [isDirty, send, analyticsName])

    const onSubmit = (user: any) => {
        if (state.context.calc_type === 'Pensions') {
            user = { ...user, retirementAge: 66 }
        } else if (state.context.calc_type === 'IncomeProtection') {
            user = { ...user, retirementAge: 65 }
        }
        // user = { ...user, retirementAge: 66 }
        send({
            type: 'Get Quote' as string,
            payload: {
                user: user,
                calc_type: calculatorType,
                name: analyticsName,
                event: 'completed',
            },
        } as CalculatorMachineEvent)
    }
    const handleHelpButton = () => {
        onOpen()
        send({
            type: 'Need Help',
            payload: {
                name: analyticsName,
                event: 'need help clicked',
            },
        } as CalculatorMachineEvent)
    }
    return (
        <form onSubmit={handleSubmit(onSubmit)}>
            <Card py='36px !important' px='16px !important'>
                <>
                    {transformedSteps?.[state.context.screenNo]
                        ?.cardCaption && (
                        <Box display={'flex'}>
                            {transformedSteps?.[state.context.screenNo]
                                ?.icon && (
                                <img
                                    style={{ paddingBottom: '46px' }}
                                    alt='ProductListCard Icon'
                                    src={
                                        transformedSteps?.[
                                            state.context.screenNo
                                        ]?.icon
                                    }
                                />
                            )}
                            <Text
                                pl={'8px'}
                                paddingBottom={'24px'}
                                variant={'body-md'}
                            >
                                {
                                    transformedSteps?.[state.context.screenNo]
                                        ?.cardCaption
                                }
                            </Text>
                        </Box>
                    )}
                    {questions.map((calculatorStep: CalculatorStepProps) => {
                        return (
                            <CalculatorStep
                                control={control}
                                key={calculatorStep?.fieldName}
                                props={calculatorStep}
                                register={register}
                                errors={errors}
                                formValues={formValues}
                            />
                        )
                    })}
                </>
                {transformedSteps?.[(state as any).context.screenNo]?.info && (
                    <>
                        <Button
                            size='sm'
                            variant='ghost'
                            px={'0'}
                            onClick={handleHelpButton}
                        >
                            <img
                                style={{ paddingRight: '8px' }}
                                alt='question mark icon'
                                src='https://assets-eu-01.kc-usercontent.com/ffd8d21b-ebd4-0151-55d5-297335c8f50f/f2023879-48b2-416f-8c20-e66002e8d1c3/question%20mark.svg'
                            />
                            Need help
                        </Button>
                        <Modal
                            finalFocusRef={finalRef}
                            isOpen={isOpen}
                            onClose={onClose}
                        >
                            <ModalOverlay />
                            <ModalContent p={'16px'}>
                                <ModalCloseButton />
                                <ModalBody>
                                    <RichText
                                        label={
                                            transformedSteps?.[
                                                (state as any).context.screenNo
                                            ]?.info
                                        }
                                        html
                                    ></RichText>
                                </ModalBody>
                                <ModalFooter>
                                    <Button
                                        colorScheme='blue'
                                        size='sm'
                                        width={'100%'}
                                        onClick={onClose}
                                    >
                                        Close
                                    </Button>
                                </ModalFooter>
                            </ModalContent>
                        </Modal>
                    </>
                )}
            </Card>
            {!isSubmit && (
                <Button
                    my={'16px'}
                    rightIcon={<ArrowRightCircleIcon />}
                    size='lg'
                    variant='primary'
                    onClick={() => {
                        send({
                            type: 'Next Step',
                            payload: {
                                name: analyticsName,
                                event: 'next step',
                                user: formValues,
                            },
                        } as CalculatorMachineEvent)
                        navigate('#ProgressIndicator')
                    }}
                    type='button'
                    disabled={!isFormComplete || !isValid}
                    _disabled={{
                        color: '#0c1e68 !important',
                        bg: '#cfd4fa !important',
                    }}
                >
                    {
                        transformedSteps?.[(state as any).context.screenNo]
                            ?.buttonCaption
                    }
                </Button>
            )}
            {isSubmit && (
                <Button
                    isLoading={isFetching}
                    loadingText='Calculating...'
                    my={'16px'}
                    rightIcon={<ArrowRightCircleIcon />}
                    size='lg'
                    variant='primary'
                    type='submit'
                    isDisabled={!isFormComplete || isFetching || !isValid}
                    onClick={() => {
                        navigate('#ProgressIndicator')
                    }}
                    _disabled={{ color: '#0c1e68', bg: '#cfd4fa' }}
                    _loading={{
                        color: '#0c1e68 !important',
                        bg: '#cfd4fa !important',
                    }}
                >
                    {
                        transformedSteps?.[(state as any).context.screenNo]
                            ?.buttonCaption
                    }
                </Button>
            )}
        </form>
    )
}
