import React from 'react'
import {
    Accordion,
    AccordionProps,
} from '@irishlife/ilgroupdesignsystem.molecules.accordion'
import { Text } from '@irishlife/ilgroupdesignsystem.atoms.text'
import styled from 'styled-components'
import { Box } from '@chakra-ui/react'

interface AccordionSectionProps extends AccordionProps {
    title: string
    text: string
}

const StyledAccordion = styled(Box)`
    & ul {
        list-style-position: inside;
    }
`

export const AccordionSection: React.FC<AccordionProps> = (props) => {
    const data = getAccordionSectionData(props)

    return (
        <>
            <Text variant='title-xl' mb={4}>
                {data.title}
            </Text>
            <Text variant='subtitle-md' mb={10}>
                {data.text}
            </Text>
            <StyledAccordion>
                <Accordion items={data.items} />
            </StyledAccordion>
        </>
    )
}

export const getAccordionSectionData = (data: any): AccordionSectionProps => {
    return {
        title: data.accordion_section__title,
        text: data.accordion_section__text,
        items: data.accordion_section__items.map((item: any) => {
            return {
                id: getRandomInt(100),
                header: item.heading,
                icon: item.icon,
                children: item.children_test,
            }
        }),
    }
}

function getRandomInt(max: number) {
    return Math.floor(Math.random() * max)
}
