import { Box } from '@chakra-ui/react'
import { Controller } from 'react-hook-form'
import React from 'react'
import { Choice } from '@irishlifedigitalhub/ds.molecules.choice'
export interface CalculatorStepBinaryChoiceProps {
    fieldName: string
    name?: string
    label?: string
    options?: any[]
    control?: any
}
export const CalculatorStepBinaryChoice: React.FC<CalculatorStepBinaryChoiceProps> =
    ({ name, label, options, fieldName, control }) => {
        return (
            <Controller
                name={fieldName ?? name}
                control={control}
                defaultValue=''
                render={({ onChange }) => (
                    <Box>
                        <Choice
                            key={fieldName}
                            defaultSelected={['option-1']}
                            isSecondary={true}
                            size={'md'}
                            id={fieldName ?? name}
                            label={label}
                            isBinary={true}
                            onChange={(value: any) => {
                                if (value === 'Y') {
                                    onChange('Y')
                                } else if (value === 'N') {
                                    onChange('N')
                                } else {
                                    onChange(value)
                                }
                            }}
                            options={[
                                {
                                    id: 'option-1',
                                    label: options?.[0]?.label,
                                    value: options?.[0]?.value,
                                },
                                {
                                    id: 'option-2',
                                    label: options?.[1]?.label,
                                    value: options?.[1]?.value,
                                },
                            ]}
                            spacing={3}
                        />
                    </Box>
                )}
            />
        )
    }
